import WsProvider from './provider/WSProvider'

var serviceName = "webservis";
var moduleName = "referans";

var referansService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    referansPath: WsProvider.documentPath,
    path: WsProvider.path,

    referansListe(durum, baslangic, limit) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "referansListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    referansDurumDegis(referansID, durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            referansID,
            durum,
            serviceName: moduleName,
            methodName: "referansAktifPasifYap"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    referansTanimla(baslik, siraNo, resim, durum) {
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            baslik,
            siraNo,
            resim,
            durum,
            serviceName: moduleName,
            methodName: "referansEkle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    referansGuncelle(referansID, baslik, siraNo) {
        //guncellerken resmi güncelletmiyoruz
        var userDataDemirag = localStorage.getItem("userDataDemirag")
        var param = {
            token: userDataDemirag,
            referansID,
            baslik,
            siraNo,
            serviceName: moduleName,
            methodName: "referansGuncelle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

}

export default referansService