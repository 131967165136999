<template>
    <div class="page-table mainDiv scrollable only-y" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            Referanslar

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" size="mini" type="text" icon="el-icon-refresh"></el-button>
                </el-tooltip>
            </div>
        </div>
        
        <el-row :gutter="10">
            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                <div class="page-header card-base card-shadow--medium animated fadeInUp pt-20" v-loading="loading || stateLoading"
                :element-loading-text='loading ? "Referanslar Yükleniyor. Lütfen Bekleyiniz" : "Referans Durum Güncelleniyor. Lütfen Bekleyiniz."'
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255, 255, 255, 1)">
                <el-row class="mt-0 mb-10">
                    <el-col :lg="16" :md="16" :sm="24" :xs="24">
                        <div>
                            <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9" text-color="gray">
                                <el-radio-button label="1">
                                    <i class="mdi mdi-page-next mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                    <transition name="slide-fade">
                                        <label v-if="selectIcon == '1'">Yayındaki Referanslar</label>
                                    </transition>
                                </el-radio-button>

                                <el-radio-button label="0">
                                    <i class="mdi mdi-page-next mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                    <transition name="slide-fade">
                                        <label v-if="selectIcon == '0'">Taslaktaki Referanslar</label>
                                    </transition>
                                </el-radio-button>
                            </el-radio-group>
                        </div>
                    </el-col>
                    <el-col :lg="8" :md="8" :sm="24" :xs="24">
                        <el-col :lg="24" :md="24" :sm="24" :xs="24">
                            <ul class="islemler">
                                <li class="duzenle">
                                    <i class="mdi mdi-pen"></i> {{$t("src.views.apps.rakamlar.islem.duzenle")}}
                                </li>
                                <li v-if="selectIcon == '1'" class="sil">
                                    <i class="mdi mdi-trash-can"></i> {{$t("src.views.apps.rakamlar.islem.sil")}}
                                </li>
                                <li v-else class="aktif">
                                    <i class="mdi mdi-play"></i> {{$t("src.views.apps.rakamlar.islem.yayinaAl")}}
                                </li>
                            </ul>
                        </el-col>
                    </el-col>
                </el-row>

                <el-table border stripe :data="referansListe" style="width: 100%">
                    <!--
                    <el-table-column
                        :label='$t("src.views.apps.genel.sira")'
                        type="index"
                        :index="indexMethod"
                        width="80">
                    </el-table-column>
                    -->

                    <el-table-column  
                        label='Referans ID'
                        prop="referansID"
                        width="120">
                    </el-table-column>

                    <el-table-column  
                        label='Sıra No'
                        prop="siraNo"
                        width="120">
                    </el-table-column>

                    <el-table-column :label='$t("src.views.apps.urun.liste.table.image")'  width="100" align="center">
                        <template slot-scope="scope">
                            <el-popover transition="el-fade-in-linear" placement="right" width="auto" trigger="hover">
                                <img style="max-height: 600px" v-if="scope.row.resim" :src="imagepath+scope.row.resim" :alt="scope.row.baslik">
                                <span v-else>Resim Yok.</span>
                                <img v-if="scope.row.resim" style="max-height: 50px !important" slot="reference" :src="imagepath+scope.row.resim" :alt="scope.row.baslik">
                                <i v-else style="font-size: 24px; cursor: pointer; color: #337ab7" slot="reference" class="el-icon-picture"></i>
                            </el-popover>
                        </template>
                    </el-table-column>


                    <el-table-column  
                        label='Referans Adı'
                        prop="baslik"
                        width="auto">
                        <template slot-scope="prop">
                            <div class="new-line-text">
                                {{prop.row.baslik}}
                            </div>
                        </template>
                    </el-table-column>
                    
                    <el-table-column :label='$t("src.views.apps.genel.islem")' align="right" width="150">
                        <template slot-scope="scope">
                            <el-tooltip :content='$t("src.views.apps.rakamlar.islem.duzenle")' :open-delay="500" placement="bottom">
                                <el-button
                                    v-on:click="updateStart(scope.row); "
                                    type="text"
                                    style="color: #f7ba2a; margin-left: 15px !important;"
                                    icon="mdi mdi-pen mdi-24px">
                                </el-button>
                            </el-tooltip>
                            <el-tooltip :content='$t("src.views.apps.rakamlar.islem.sil")' :open-delay="500" placement="bottom">
                                <el-button
                                    v-if="selectIcon == '1'"
                                    v-on:click="referansDurumDegis(scope.row, '0')"
                                    type="text"
                                    class="buttonDel"
                                    style="margin-left: 15px !important;"
                                    icon="mdi mdi-trash-can mdi-24px">
                                </el-button>
                            </el-tooltip>
                            <el-tooltip :content='$t("src.views.apps.rakamlar.islem.yayinaAl")' :open-delay="500" placement="bottom">
                                <el-button
                                    v-if="selectIcon == '0'"
                                    v-on:click="referansDurumDegis(scope.row,'1')"
                                    type="text"
                                    class="buttonDel ml-15"
                                    icon="mdi mdi-play mdi-24px">
                                </el-button>
                            </el-tooltip>
                        </template>
                     </el-table-column>
                </el-table>

                <el-pagination
                    background
                    layout="sizes,prev, pager, next, jumper, total"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="pageChange"
                    :page-sizes="[10, 20, 50, 100]"
                    :current-page.sync="page"
                    :next-click.sync="page"
                    :prev-click.sync="page"
                    @next-click="pageChange"
                    @prev-click="pageChange">
                </el-pagination>
            </div>
        </el-col>

        <el-col :lg="6" :md="6" :sm="24" :xs="24">
            <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp" v-loading="addLoading || updateLoading"
            :element-loading-text='addLoading ? "Referans Tanımlanıyor. Lütfen Bekleyiniz." : "Referans Güncelleniyor. Lütfen Bekleyiniz."'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
                <el-form status-icon :model="referansForm" :rules="rulesReferans" ref="referansForm" class="demo-ruleForm">
                    <el-row>
                        
                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                            <el-form-item :label='"Referans Adı"' prop="baslik">:
                                <el-input size="small" v-on:change="$store.commit('changeTabsClose', false)" v-model="referansForm.baslik" :placeholder='"Referans Adı Giriniz"'></el-input>
                            </el-form-item>
                            <el-form-item :label='"Referans Sırası"' prop="siraNo">:
                                <el-input size="small" v-on:change="$store.commit('changeTabsClose', false)" v-model="referansForm.siraNo" :placeholder='"Referans Sırası Giriniz"'></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col v-if="btnVisible" :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                            <el-form-item :label='"Resim"' prop="resim">:
                                <el-upload
                                    id="referansekle-resim"
                                    action=""
                                    :auto-upload="false"
                                    :limit="1"
                                    :file-list="imageListMain"
                                    :on-change="imageUpload"
                                    :on-remove="imageRemove"
                                    accept=".jpg,.jpeg,.png,"
                                    list-type="picture-card">
                                    <i slot="default" class="el-icon-plus"></i>
                                </el-upload>
                            </el-form-item>
                        </el-col>

                        <el-col :span="24" class="col-p">
                            <el-form-item>
                                <el-button v-if="btnVisible" size="small" v-on:click="referansTanimla('referansForm')" type="primary">
                                    {{$t('src.views.apps.rakamlar.islem.ekle')}}
                                </el-button>
                                <el-button v-else size="small" v-on:click="referansGuncelle('referansForm')" type="primary">
                                    {{$t('src.views.apps.rakamlar.islem.guncelle')}}
                                </el-button>
                                <el-button  size="small" v-on:click="vazgec('referansForm')">
                                    {{$t('src.views.apps.rakamlar.islem.vazgec')}}
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-col>
    </el-row>
        
</div>
</template>

<script>

    import referansService from '../../../WSProvider/ReferansService'

    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'
    import JQuery from 'jquery';

    let $ = JQuery;

    export default {
        name: "ReferansListe",
        mounted() {
            this.getReferansList();
        },
        data() {
            return {
                loading: false,
                updateLoading: false,
                stateLoading: false,
                addLoading: false,
                selectIcon: '1',
                radio: '1',
                referansListe: [],
                btnVisible: true,
                selection: '',

                imagepathKucuk: referansService.imagePathKucuk,
                path: referansService.path,
                imagepath: referansService.imagePath,

                sizes: 10,
                page: 0,
                postPage: 0,
                total: null,

                imageListMain: [],
                referansForm: {
                    referansID: "",
                    siraNo: "",
                    baslik: "",
                    referans: [],
                    durum: "1",
                },

                rulesReferans: {
                    referans: [{required: true, message: "Lütfen Referans Yükleyiniz!", trigger: ['blur', 'input']}]
                },
            }
        },
        methods: {
            refreshPage(){
                this.page = 1;
                this.postPage = 0;
                this.getReferansList();
            },

            downloadFile(item){
                var downloadLink = document.createElement("a");
                downloadLink.href = referansService.referansPath + item.resim;
                downloadLink.download = item.baslik;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            },

            getReferansList() {
                try{
                    this.loading = true;
                    referansService.referansListe(this.selectIcon, this.postPage, this.sizes).then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);
                            response.data.forEach(el => {
                                el = functions.removeSlashesAndDecodeStrings(el)
                            })
                            this.referansListe = response.data;
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.referansListe = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    this.loading = false;
                }
            },

            imageRemove() {
                $('#referansekle-resim .el-upload--picture-card').show();
                this.imageListMain = [];
                this.referansForm.referans = [];
            },

            imageUpload(file, fileList) {
                this.imageListMain = [];
                this.referansForm.referans = [];

                if ((file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg' || file.raw["type"] === 'image/png') && this.imageListMain.length < 2) {
                    var item = {
                    name: file.name,
                    url: file.url,
                    id: file.raw.lastModified,
                    size: file.raw.size
                    };
                    this.imageListMain.push(item);

                    file.raw["tmp_name"] = file.url;

                    this.referansForm.referans.push(file.raw);

                    fileList.pop();

                    //$('#referansekle-resim .el-upload--picture-card').hide();
                }
            },

            vazgec(formName) {
                this.$refs[formName].resetFields();
                this.btnVisible = true;
                this.imageListMain = [];
                this.referansForm.referans = []
                $('#referansekle-resim .el-upload--picture-card').show();
            },

            updateStart(scope) {
                if(scope.resim != ''){
                    this.imageListMain.push({
                        name: scope.resim,
                        url: referansService.imagePath + scope.resim
                    });
                }
                this.referansForm.referans.push(scope.resim);

                this.referansForm.referansID = scope.referansID;
                this.referansForm.baslik = scope.baslik;
                this.referansForm.siraNo = scope.siraNo;
                this.referansForm.durum = scope.durum;
                $('#referansekle-resim .el-upload--picture-card').hide();
                //this.btnVisible = false;
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            referansGuncelle(formName) {
                if(this.validateForm(formName)){
                    this.openConfirmDialog("Referansı güncellemek istediğinize emin misiniz?").then(() => {
                        try{
                            this.updateLoading = true;
                                referansService.referansGuncelle(this.referansForm.referansID, this.referansForm.baslik, this.referansForm.durum).then((response) => {
                                if (response.status == 200) {
                                    notification.Status('success', this, response.msg);
                                    this.getReferansList();
                                    this.vazgec(formName)
                                }
                                this.updateLoading = false;
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        EventBus.$emit("userLogout", true)
                                    }
                                    notification.Status("warning", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.updateLoading = false;
                            })
                        }catch(e){
                            this.updateLoading = false
                        }
                    })
                }
            },

            referansTanimla(formName) {
                if(this.validateForm(formName)){
                    this.openConfirmDialog("Referansı eklemek istediğinize emin misiniz?").then(() => {
                        try{
                            this.addLoading = true;
                            referansService.referansTanimla(this.referansForm.baslik, this.referansForm.siraNo, this.referansForm.referans[0], this.referansForm.durum).then(response => {
                                if(response.status == 200){
                                    localStorage.setItem("userDataDemirag", response.token)
                                    this.getReferansList();
                                    this.vazgec(formName);
                                }
                                this.addLoading = false;
                                notification.Status("success", this, response.msg)
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.addLoading = false;
                            })
                        }catch(e){
                            this.addLoading = false;
                        }
                    })
                }
            },

            referansDurumDegis(selection, durum) {
                var msg = durum == '0' ? "Referansı yayından kaldırmak istediğinize emin misiniz?" : "Referansı yayına almak istediğinize emin misiniz?"
                this.openConfirmDialog(msg).then(() => {
                    try{
                        this.stateLoading = true;
                        referansService.referansDurumDegis(selection.referansID, durum).then(response => {
                            if (response.status == 200) {
                                localStorage.setItem("userDataDemirag", response.token)
                                this.getReferansList();
                                this.stateLoading = false;
                            }
                            notification.Status("success", this, response.msg)
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                else notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.stateLoading = false;
                        })
                    }catch(e){
                        this.stateLoading = false;
                    }
                })                
            },

           handleSizeChange(val) {
                this.sizes = val;
                this.getReferansList();
            },

            handleChange(event) {
                this.selectIcon = event;
                this.postPage = 0;
                this.page = 1;
                this.getReferansList();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.getReferansList();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            indexMethod(index){
                return ((this.page - 1)* 10) + index + 1;
            },
        }
    }
</script>

<style lang="scss">
@import '../../../assets/scss/_variables';
    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    // el-uploada ait animasyonu kaldırmak için eklendi
    #referansekle-resim{
        .el-upload-list__item {
            transition: none !important;
        }
    }
</style>
